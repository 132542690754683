.App {
  text-align: center;
}


.App-container {
  min-height: 100vh;
  
  min-width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.faugbtn{
  border: solid;
  border-color: black;
  width: 5rem;
  border-radius: 5%;
  box-shadow: 2px 2px 4px black;
}

.titleTxt{
  margin-top:7rem;
  max-width: 80%;
}

.cards{
  display: flex;
  max-width: 90%;
  margin-top: 5rem;
  justify-content: center;
  align-items: center;
}
.App-link {
  color: #61dafb;
}


